type filterData = {
  showDateFilter?: boolean;
  setShowDateFilter?: any;
  showStatusFilter?: boolean;
  setShowStatusFilter?: any;
  title?: string;
  makeSearchTable?: any;
  setApplyFilters?: any;
  handleDropdown?: any;
};

const FilterButton = ({
  showDateFilter,
  setShowDateFilter,
  showStatusFilter,
  setShowStatusFilter,
  title,
  makeSearchTable,
  setApplyFilters,
  handleDropdown,
}: filterData) => {
  const handleClick = () => {
    setShowDateFilter && setShowDateFilter(!showDateFilter);
    setShowStatusFilter && setShowStatusFilter(!showStatusFilter);
    if (makeSearchTable) {
      setApplyFilters(true);
      // makeSearchTable();
    }
  };

  return (
    <div
      className="mb-3"
      style={
        title === "Aplicar Filtros"
          ? { flexBasis: "60%", marginBottom: "8px" }
          : {}
      }
      onClick={handleClick}
    >
      <label tabIndex={0} className="cursor-pointer" onClick={handleDropdown}>
        <div
          className={`${
            title === "Aplicar Filtros"
              ? "px-5 w-full py-2.5 relative rounded-2xl group text-white font-medium inline-block h-12 text-center"
              : "px-5 w-36 py-2.5 relative rounded-2xl group text-white font-medium inline-block h-12 text-center"
          }`}
        >
          <span className="absolute top-0 left-0 w-full h-full rounded-2xl filter bg-[#1890ff]"></span>
          <span className="relative flex items-center justify-center h-full w-full text-xl rounded-2xl">
            {title}{" "}
            {title === "Aplicar Filtros" ? null : (
              <span className="ml-4">+</span>
            )}
          </span>
        </div>
      </label>
    </div>
  );
};

export default FilterButton;
