import axios from "axios";
import { loadAbort } from "../utilities";

const route = process.env.REACT_APP_API_SESSION_URL + "session";
const pointSessionURL = process.env.REACT_APP_API_SESSION_URL + "pointSession";
const assignmentURL = process.env.REACT_APP_API_SESSION_URL + "assignment";
const alignmentURL = process.env.REACT_APP_API_SESSION_URL + "alignmentSession";

export const getAllDashboard = () => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllDashboard`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAllSessions = (page: number = 1, pageSize: number = 10) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/GetAllSessions?page=${page}&pageSize=${pageSize}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getCoachSessionsWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  coachId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetCoachSessionsWithFilters?page=${page}&pageSize=${pageSize}`,
        coachId,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  text: any,
  filters: any // Cambio en la firma de la función
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetAllDashboardWithFilters?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        filters, // Se pasa el objeto filters en el cuerpo de la solicitud
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getSearchWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  filters: any
) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.patch(
        `${route}/GetSearchWithFilters?page=${page}&pageSize=${pageSize}`,
        filters,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAlignmentSearchWithFilters = (
  page: number = 1,
  pageSize: number = 10,
  filters: any
) => {
  const controller = loadAbort();
  console.log("filters", filters);
  return {
    call: () =>
      axios.patch(
        `${alignmentURL}/GetSearchWithFilters?page=${page}&pageSize=${pageSize}`,
        filters,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const GetSessionsWithFiltersNoShow = (
  page: number = 1,
  pageSize: number = 10,
  filters: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/GetSessionsWithFiltersNoShow?page=${page}&pageSize=${pageSize}`,
        filters,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllSearchWithFilters = (filters: any) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(`${route}/GetAllSearchWithFilters`, filters, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getSearchByText = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/SearchByText?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getSearchByTextNoShow = (
  page: number = 1,
  pageSize: number = 10,
  text: any
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.patch(
        `${route}/SearchByTextNoShow?page=${page}&pageSize=${pageSize}&searchQuery=${text}`,
        null,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getByCoachId = (
  page: number = 1,
  pageSize: number = 10,
  coachId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/coach/${coachId}?page=${page}&pageSize=${pageSize}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getByCoachId2 = (
  page: number = 1,
  pageSize: number = 10,
  coachId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/coach2/${coachId}?page=${page}&pageSize=${pageSize}`,
        {
          signal: controller.signal,
        }
      ),
    controller,
  };
};

export const coachSessionsWithNoShow = (
  page: number = 1,
  pageSize: number = 10,
  coachId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/coachSessionsWithNoShow/${coachId}?page=${page}&pageSize=${pageSize}`,
        {
          signal: controller.signal,
        }
      ),
    controller,
  };
};

export const getByCoacheeId = (
  page: number = 1,
  pageSize: number = 10,
  coacheeId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/coachee/${coacheeId}?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getByCoacheeId2 = (
  page: number = 1,
  pageSize: number = 10,
  coacheeId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/coachee2/${coacheeId}?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const coacheeSessionsWithNoShow = (
  page: number = 1,
  pageSize: number = 10,
  coacheeId
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/coacheeSessionsWithNoShow/${coacheeId}?page=${page}&pageSize=${pageSize}`,
        { signal: controller.signal }
      ),
    controller,
  };
};

export const getAllSessionCoachee = (id: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/coachee/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const getAllSessionCoach = (id: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/coachDashboard/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const BySessionDate = (id: string, since: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/BySessionDate/${since}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const ByEvaluationOrder = (id: string, evaluation: string) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(`${route}/ByEvaluationOrder/${evaluation}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getById = (id: string) => {
  const controller = loadAbort();
  return {
    call: () => axios.get(`${route}/${id}`, { signal: controller.signal }),
    controller,
  };
};

export const updateSession = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/update/${id}`,
        { ...data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const deleteAlignmentSession = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${alignmentURL}/deleteFromDB/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const removeSession = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${route}/DeleteSession/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const deleteSession = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${route}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const cancelSession = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/cancel2/${id}`,
        { data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const rescheduleSession = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(
        `${route}/reschedule2/${id}`,
        { data },
        { signal: controller.signal }
      ),
    controller,
  };
};

export const addPointSession = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(pointSessionURL, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const removePointSession = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${pointSessionURL}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const updatePointSession = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(`${pointSessionURL}/${id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const addAssignment = (data) => {
  const controller = loadAbort();

  return {
    call: () =>
      axios.post(assignmentURL, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const removeAssignment = (id) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.delete(`${assignmentURL}/${id}`, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const updateAssignment = (id, data) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.put(`${assignmentURL}/${id}`, data, {
        signal: controller.signal,
      }),
    controller,
  };
};

export const getAllNoShowSessions = (
  page: number = 1,
  pageSize: number = 10
) => {
  const controller = loadAbort();
  return {
    call: () =>
      axios.get(
        `${route}/getAllNoShowSessions?page=${page}&pageSize=${pageSize}`,
        {
          signal: controller.signal,
        }
      ),
    controller,
  };
};
